<template src="./HelpCenter.htm">
 
</template>
<script>
import { mapState } from 'vuex';
import SubHeader from '@/layouts/sub_header/Subheader.vue';


export default {

  name: "Help_Center",
  components: {
      'sub-header': SubHeader,
  },
  data: () => ({
    valid: true,
    view_table: true,
    view_info: false,
    overlay: true,
    name: '',
    is_admin: '',
    userprofile: [
        { title: "My Profile" },
        { title: "My Balance" },
        { title: "Inbox" },
        { title: "Account Setting" },
        { title: "Logout" }
      ]

  }),
  computed: {
    ...mapState({
        user: state => state.auth.user,
        isAuthenticated: state => state.auth.isAuthenticated,
    }),
  },
  created() {
    this.overlay = true;
  },
   mounted() {
    this.name = this.user.name;
    this.is_admin = this.user.is_admin;
    if (!this.isAuthenticated) {
      this.$router.replace({ name: 'auth.login' })
    }

    this.overlay = false;
   }

}
 </script>